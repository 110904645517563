import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import TawkTo from "tawkto-react";
import { useEffect, lazy, Suspense } from "react";
import { BsWhatsapp } from "react-icons/bs";
import { BsFillTelephoneFill } from "react-icons/bs";
import EnquiryForm from "./components/EnquiryForm.js";
import Spinner from 'react-bootstrap/Spinner';


const TYForContactPage = lazy(() => import("./pages/TYForContactPage.js"));
const HomePage = lazy(() => import("./pages/HomePage"));
const AboutUsPage = lazy(() => import("./pages/AboutUsPage"));
const ErrorPage = lazy(() => import("./pages/ErrorPage"));
const AmzFBAServicePage = lazy(() => import("./pages/AmzFBAServicePage"));
const ShopifyDropPage = lazy(() => import("./pages/ShopifyDropPage"));
const EbayDropPage = lazy(() => import("./pages/EbayDropPage"));
const WalmartDropPage = lazy(() => import("./pages/WalmartDropPage"));
const CaseStudyPage = lazy(() => import("./pages/CaseStudyPage"));
const PrivacyPolicyPage = lazy(() => import("./pages/PrivacyPolicyPage.js"));
const AreasWeServePage = lazy(() => import("./pages/AreasWeServePage.js"));
const Florida = lazy(() => import("./pages/AWSInnerPages/Florida"));
const California = lazy(() => import("./pages/AWSInnerPages/California"));
const Washington = lazy(() => import("./pages/AWSInnerPages/Washington"));
const Texas = lazy(() => import("./pages/AWSInnerPages/Texas"));
const Utah = lazy(() => import("./pages/AWSInnerPages/Utah"));
const Arizona = lazy(() => import("./pages/AWSInnerPages/Arizona"));
const Minnesota = lazy(() => import("./pages/AWSInnerPages/Minnesota"));
const Idaho = lazy(() => import("./pages/AWSInnerPages/Idaho"));
const Colorado = lazy(() => import("./pages/AWSInnerPages/Colorado"));
const Virginia = lazy(() => import("./pages/AWSInnerPages/Virginia"));
const Nevada = lazy(() => import("./pages/AWSInnerPages/Nevada"));
const Pennsylvania = lazy(() => import("./pages/AWSInnerPages/Pennsylvania"));
const Michigan = lazy(() => import("./pages/AWSInnerPages/Michigan"));
const SouthCarolina = lazy(() => import("./pages/AWSInnerPages/SouthCarolina"));
const Ohio = lazy(() => import("./pages/AWSInnerPages/Ohio"));
const Illinois = lazy(() => import("./pages/AWSInnerPages/Illinois"));
const NorthCarolina = lazy(() => import("./pages/AWSInnerPages/NorthCarolina"));
const Massachusetts = lazy(() => import("./pages/AWSInnerPages/Massachusetts"));
const NewJersey = lazy(() => import("./pages/AWSInnerPages/NewJersey"));
const Missouri = lazy(() => import("./pages/AWSInnerPages/Missouri"));
const Indiana = lazy(() => import("./pages/AWSInnerPages/Indiana"));
const Oregon = lazy(() => import("./pages/AWSInnerPages/Oregon"));
const Delaware = lazy(() => import("./pages/AWSInnerPages/Delaware"));
const Alaska = lazy(() => import("./pages/AWSInnerPages/Alaska"));
const Georgia = lazy(() => import("./pages/AWSInnerPages/Georgia"));
const Tennessee = lazy(() => import("./pages/AWSInnerPages/Tennessee"));
const Louisiana = lazy(() => import("./pages/AWSInnerPages/Louisiana"));
const Maryland = lazy(() => import("./pages/AWSInnerPages/Maryland"));
const Kentucky = lazy(() => import("./pages/AWSInnerPages/Kentucky"));
const RhodeIsland = lazy(() => import("./pages/AWSInnerPages/RhodeIsland"));
const Kansas = lazy(() => import("./pages/AWSInnerPages/Kansas"));
const Montana = lazy(() => import("./pages/AWSInnerPages/Montana"));
const Newyork = lazy(() => import("./pages/AWSInnerPages/Newyork"));


function App() {
  const location = useLocation();
  const currentPath = location.pathname;
  const handlePhoneButton = () => {
    window.location.href = "tel:+14155135890";
  };

  let routerArray = [
    "/",
    "/about-us/",
    "/amazon-fba/",
    "/shopify-dropshipping/",
    "/influencer-marketing/",
    "/walmart-dropshipping/",
    "/case-studies/",
    "/privacy-policy/",
    "/areas-we-serve/",
    "/thank-you/",
    "/amazon-marketing-services-in-florida/",
    "/amazon-marketing-services-in-california/",
    "/amazon-marketing-services-in-washington/",
    "/amazon-fba-warehouse-texas/",
    "/amazon-marketing-services-in-utah/",
    "/amazon-marketing-services-in-arizona/",
    "/amazon-marketing-services-in-minnesota/",
    "/amazon-marketing-services-in-idaho/",
    "/amazon-marketing-services-in-colorado/",
    "/amazon-marketing-services-in-virginia/",
    "/amazon-marketing-services-in-nevada/",
    "/amazon-marketing-services-in-pennsylvania/",
    "/amazon-marketing-services-in-michigan/",
    "/amazon-marketing-services-in-south-carolina/",
    "/amazon-marketing-services-in-ohio/",
    "/amazon-marketing-services-in-illinois/",
    "/amazon-marketing-services-in-north-carolina/",
    "/amazon-marketing-services-in-massachusetts/",
    "/amazon-marketing-services-in-newjersey/",
    "/amazon-marketing-services-in-missouri/",
    "/amazon-marketing-services-in-indiana/",
    "/amazon-marketing-services-in-oregon/",
    "/amazon-marketing-services-in-delaware/",
    "/amazon-marketing-services-in-alaska/",
    "/amazon-marketing-services-in-georgia/",
    "/amazon-marketing-services-in-tennessee/",
    "/amazon-marketing-services-in-louisiana/",
    "/amazon-marketing-services-in-maryland/",
    "/amazon-marketing-services-in-kentucky/",
    "/amazon-marketing-services-in-rhodeisland/",
    "/amazon-marketing-services-in-kansas/",
    "/amazon-marketing-services-in-montana/",
    "/amazon-marketing-services-in-newyork/",
  ];

  let identifier = routerArray.includes(currentPath);

  const handleButton = () => {
    var url = "https://wa.me/+14155135890";
    window.open(url);
  };

  let propertyId = "6428773631ebfa0fe7f5f406";
  let tawkId = "1gsv13bnc";
  useEffect(() => {
    if (identifier) {
      var tawk = new TawkTo(propertyId, tawkId);

      tawk.onStatusChange((status) => { });
    }
  }, [identifier]);

  return (
    <div>
      {identifier ? (
        <>
          {currentPath !== "/thank-you/" && <EnquiryForm />}
          <div className="layout-left-icon">
            <div className="wrapper">
              <div className="button btn-1" onClick={handlePhoneButton}>
                <div className="first-icon">
                  <i>
                    <BsFillTelephoneFill color="white" size={25} />
                  </i>
                </div>
                <span className="phone">Phone</span>
              </div>
            </div>
          </div>
          <div className="layout-left-icon-second">
            <div className="wrapper">
              <div className="button btn-2" onClick={handleButton}>
                <div className="second-icon">
                  <i>
                    <BsWhatsapp color="white" size={25} />
                  </i>
                </div>
                <span>Whatsapp</span>
              </div>
            </div>
          </div>
        </>
      ) : null}
      <Suspense fallback={<div className="loading-container"><Spinner animation="grow" /></div>}>
        <Routes>
          <Route path="*" element={<ErrorPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/about-us/" element={<AboutUsPage />} />
          <Route path="/amazon-fba/" element={<AmzFBAServicePage />} />
          <Route path="/shopify-dropshipping/" element={<ShopifyDropPage />} />
          <Route path="/influencer-marketing/" element={<EbayDropPage />} />
          <Route path="/walmart-dropshipping/" element={<WalmartDropPage />} />
          <Route path="/case-studies/" element={<CaseStudyPage />} />
          <Route path="/privacy-policy/" element={<PrivacyPolicyPage />} />
          <Route path="/areas-we-serve/" element={<AreasWeServePage />} />
          <Route path="/thank-you/" element={<TYForContactPage />} />
          <Route
            path="/amazon-marketing-services-in-florida/"
            element={<Florida />}
          />
          <Route
            path="/amazon-marketing-services-in-california/"
            element={<California />}
          />
          <Route
            path="/amazon-marketing-services-in-washington/"
            element={<Washington />}
          />
          <Route path="/amazon-fba-warehouse-texas/" element={<Texas />} />
          <Route path="/amazon-marketing-services-in-utah/" element={<Utah />} />
          <Route
            path="/amazon-marketing-services-in-arizona/"
            element={<Arizona />}
          />
          <Route
            path="/amazon-marketing-services-in-minnesota/"
            element={<Minnesota />}
          />
          <Route
            path="/amazon-marketing-services-in-idaho/"
            element={<Idaho />}
          />
          <Route
            path="/amazon-marketing-services-in-colorado/"
            element={<Colorado />}
          />
          <Route
            path="/amazon-marketing-services-in-virginia/"
            element={<Virginia />}
          />
          <Route
            path="/amazon-marketing-services-in-nevada/"
            element={<Nevada />}
          />
          <Route
            path="/amazon-marketing-services-in-pennsylvania/"
            element={<Pennsylvania />}
          />
          <Route
            path="/amazon-marketing-services-in-michigan/"
            element={<Michigan />}
          />
          <Route
            path="/amazon-marketing-services-in-south-carolina/"
            element={<SouthCarolina />}
          />
          <Route path="/amazon-marketing-services-in-ohio/" element={<Ohio />} />
          <Route
            path="/amazon-marketing-services-in-illinois/"
            element={<Illinois />}
          />
          <Route
            path="/amazon-marketing-services-in-north-carolina/"
            element={<NorthCarolina />}
          />
          <Route
            path="/amazon-marketing-services-in-massachusetts/"
            element={<Massachusetts />}
          />

          <Route
            path="/amazon-marketing-services-in-newjersey/"
            element={<NewJersey />}
          />
          <Route
            path="/amazon-marketing-services-in-missouri/"
            element={<Missouri />}
          />
          <Route
            path="/amazon-marketing-services-in-indiana/"
            element={<Indiana />}
          />
          <Route
            path="/amazon-marketing-services-in-oregon/"
            element={<Oregon />}
          />
          <Route
            path="/amazon-marketing-services-in-delaware/"
            element={<Delaware />}
          />
          <Route
            path="/amazon-marketing-services-in-alaska/"
            element={<Alaska />}
          />
          <Route
            path="/amazon-marketing-services-in-georgia/"
            element={<Georgia />}
          />
          <Route
            path="/amazon-marketing-services-in-tennessee/"
            element={<Tennessee />}
          />
          <Route
            path="/amazon-marketing-services-in-louisiana/"
            element={<Louisiana />}
          />
          <Route
            path="/amazon-marketing-services-in-maryland/"
            element={<Maryland />}
          />
          <Route
            path="/amazon-marketing-services-in-kentucky/"
            element={<Kentucky />}
          />
          <Route
            path="/amazon-marketing-services-in-rhodeisland/"
            element={<RhodeIsland />}
          />
          <Route
            path="/amazon-marketing-services-in-kansas/"
            element={<Kansas />}
          />
          <Route
            path="/amazon-marketing-services-in-montana/"
            element={<Montana />}
          />
          <Route
            path="/amazon-marketing-services-in-newyork/"
            element={<Newyork />}
          />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
